import Vue from 'vue'
import Vuex from 'vuex'
import PermissionStorage from "@/scripts/services/permissionStorage.service"
import CartStorage from "@/scripts/services/cartStorage.service"
import PersonInfoStorage from "@/scripts/services/personInfoStorage.service"
import CartModule from "@/scripts/modules/cart.module"
import CardModule from '@/scripts/modules/cards.module'
import CampaignModule from "@/scripts/modules/campaign.module"
import ProjectModule from "@/scripts/modules/project.module"
import CheckoutModule from "@/scripts/modules/checkout.module"
import Person from "@/scripts/models/person.model"
import { flat } from "@/scripts/helpers/list.helper";
import router from "./router";
import ManualReceiptsModule from './scripts/modules/manualReceipts.module'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: { cartModule: CartModule, campaign: CampaignModule, project: ProjectModule, manualReceipt: ManualReceiptsModule, checkoutModule: CheckoutModule, cardModule: CardModule },
	state: {
		snackbarMessage: '',
		snackbarSuccessMessage: '',
		snackbarWarningMessage: '',
		snackbarInfoMessage: '',
		showLoader: false,
		loaderCount: 0,
		includeOrRemoveCssBasedOnRoute: false,
		permissionsLoaded: false,
		ticketCart: null,
		socialMediaLogin: null,
		missingInformation: null,
		showLogin: false,
		showPersonInfo: false,
		authenticated: null,
		reloadCart: PermissionStorage.getReloadCart(),
		registerUser: new Person(),
		registerStep: 1,
		userProfile: null,
		cartSuccess : null,
		route: null,
		isSimplifiedCheckout: false
	},
	mutations: {
		SET_SNACKBAR_MESSAGE: function (state, value) {
			state.snackbarMessage = value;
		},
		SET_SNACKBAR_SUCCESS_MESSAGE: function (state, value) {
			state.snackbarSuccessMessage = value;
		},
		SET_SNACKBAR_WARNING_MESSAGE: function (state, value) {
			state.snackbarWarningMessage = value;
		},
		SET_SNACKBAR_INFO_MESSAGE: function (state, value) {
			state.snackbarInfoMessage = value;
		},
		SET_LOADER: function (state, value) {
			if (this.loaderCount == null || this.loaderCount === undefined)
				this.loaderCount = 0;
			if (value) {
				this.loaderCount++;
				if (this.loaderCount == 1)
					state.showLoader = true;
			}
			else {
				this.loaderCount--;
				if (this.loaderCount == 0)
					state.showLoader = false;
			}
		},
		SET_INCLUDE_OR_REMOVE_CSS_BASED_ON_ROUTE: function (state, value) {
			state.includeOrRemoveCssBasedOnRoute = value;
		},
		SET_ROLES: function (state, value) {
			PermissionStorage.setRoles(value);
			this.commit('UPDATE_PERMISSIONS_LOADED', true);
		},
		REMOVE_ROLES: function (state) {
			PermissionStorage.removeRoles();
			this.commit('UPDATE_PERMISSIONS_LOADED', false);
		},
		UPDATE_PERMISSIONS_LOADED: function (state, value) {
			state.permissionsLoaded = value;
		},
		SET_TICKET_CART: function (state, value) {
			CartStorage.setTicketCart(value);
			state.ticketCart = value;
		},
		REMOVE_TICKET_CART: function (state) {
			CartStorage.removeTicketCart();
		},
		AUTHENTICATE: (state, payload) => {
			state.authenticated = payload
		},
		RELOAD_CART: (state, payload) => {
			state.reloadCart = PermissionStorage.setReloadCart(payload);
		},
		SHOW_LOGIN: function (state, routeAfterLogin = null) {
			state.showLogin = true;
			state.route = routeAfterLogin;
			router.push({ name: state.route });
		},
		HIDE_LOGIN: function (state) {
			state.showLogin = false;
			if(state.logged && state.route != null){
				router.push({ name: state.route });
				state.route = null;
			}
		},
		SHOW_PERSON_INFO: function(state, payload){
			state.showPersonInfo = true;
			PersonInfoStorage.setShowPersonInfo(true);
		},
		HIDE_PERSON_INFO: function(state, payload){
			state.showPersonInfo = false;
			PersonInfoStorage.setShowPersonInfo(false);
		},
		SHOW_PERSON_INFO_NOW:function(state, payload){
			state.showPersonInfo = true;
			PersonInfoStorage.setShowPersonInfoNow(true);
		},
		REGISTER_USER: function(state, payload){
			state.registerUser = payload;
		},
		REGISTER_STEP: function(state, payload){
			state.registerStep = payload;
			localStorage.setItem('registerStep', payload);
		},
		USER_PROFILE: (state, payload) => {
			state.userProfile = payload
		},
		CART_SUCCESS: (state, payload) => {
			state.cartSuccess = payload;
		},
		SET_SOCIAL_MEDIA_LOGIN: (state, payload) => {
			PermissionStorage.setSocialMediaLogin(payload);
			state.socialMediaLogin = payload;
		},
		REMOVE_SOCIAL_MEDIA_LOGIN: function (state) {
			PermissionStorage.removeSocialMediaLogin();
		},
		SET_MISSING_INFORMATION: (state, payload) => {
			PermissionStorage.setMissingInformation(payload);
			state.missingInformation = payload;
		},
		REMOVE_MISSING_INFORMATION: function (state) {
			PermissionStorage.removeMissingInformation();
		},
		IS_SIMPLIFIED_CHECKOUT: function (state, value) {
			state.isSimplifiedCheckout = value;
		},
	},
	getters: {
		hasPermission: (state) => (requiredPermissions, entityId = null, combinePermissionsUsingAnd = false) => {
			let roles = PermissionStorage.getRoles();
			if (!roles) {
				return false;
			}
			let rolesFilteredByEntity = roles.filter(role => (entityId == null || role.entity.id == entityId));
			// let uniquePermissionsFilteredByEntity = [...new Set(rolesFilteredByEntity.map(role => role.permissionList).flat())];
			let uniquePermissionsFilteredByEntity = [...new Set(flat(rolesFilteredByEntity.map(role => role.permissionList)))];
			let requiredPermissionsOwnedByUser = uniquePermissionsFilteredByEntity.filter(permission => requiredPermissions.includes(permission));
			if (combinePermissionsUsingAnd) {
				return requiredPermissionsOwnedByUser.length == requiredPermissions.length;
			}
			return requiredPermissionsOwnedByUser.length > 0;
		},
		ticketCart: state => {
			return CartStorage.getTicketCart();
		},
		logged: state => {
			return state.authenticated;
		},
		reloadCart: state => {
			return state.reloadCart;
		},
		showLogin: state => {
			return state.showLogin
		},
		showPersonInfo: state => {
			state.showPersonInfo = PersonInfoStorage.verify() === true;
			return state.showPersonInfo;
		},
		registerUser: state => {
			return state.registerUser
		},
		registerStep: state => {
			return state.registerStep
		},
		userProfile: state => {
			return state.userProfile;
		},
		cartSuccess: state => {
			return state.cartSuccess;
		},
		socialMediaLogin: state => {
			return PermissionStorage.getSocialMediaLogin();
		},
		missingInformation: state => {
			return PermissionStorage.getMissingInformation();
		},
		isSimplifiedCheckout: state =>{
			return state.isSimplifiedCheckout;
		}
	},
	actions: {
		ticketCart(state, value) {
			state.commit('SET_TICKET_CART', value);
		},
		setLogged: (state, payload) => {
			state.commit('AUTHENTICATE', payload)
		},
		setReloadCart: (state, payload) => {
			state.commit('RELOAD_CART', payload)
		},
		setRegisterUser: (state, payload) => {
			state.commit('REGISTER_USER', payload)
		},
		setRegisterStep: (state, payload) => {
			state.commit('REGISTER_STEP', payload)
		},
		setUserProfile: (state, payload) => {
			state.commit('USER_PROFILE', payload)
		},
		setCartSuccess: (state, payload) => {
			state.commit('CART_SUCCESS', payload)
		},
		setSocialMediaLogin: (state, payload) => {
			state.commit('SET_SOCIAL_MEDIA_LOGIN', payload);
		},
		setMissingInformation: (state, payload) => {
			state.commit('SET_MISSING_INFORMATION', payload);
		},
		setSimplifiedCheckout: (state,value) =>{
			state.commit('IS_SIMPLIFIED_CHECKOUT',value)
		}
	},
})