
import BaseService from "./base.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";

const LOGGED_USER = 'logged-user'

export default class LoginService extends BaseService {

    constructor() {
        super('Auth');
    }

    login(username, password, employeesOnly, tokenRecaptcha) {
        return this.postRequest('Login', { username: username, password: password, employeesOnly: employeesOnly, tokenRecaptcha: tokenRecaptcha }, false).then(data => {
            if(data) {
                this.saveLoggedUser(username);
            }
            return data;
        });
    }

    loginByToken(token){
        return this.postRequest('LoginByToken', {token:token}).then(data => {
            if(data) {
                this.saveLoggedUser(data.email);
            }
            return data;
        });
    }

    loginByVerificationCode(verificationCode) {
        return this.postRequest('LoginByVerificationCode', { verificationCode: verificationCode }, false).then(data => {
            if(data) {
                this.saveLoggedUser(data.email);
            }
            return data;
        });
    }

    verifyToken(callback, error) {
        return this.getRequest('VerifyToken').then(callback).catch(error);
    }

    facebookLogin(accessToken) {
        return this.postRequest('FacebookLogin', { accessToken: accessToken }, false).then(data => {
            if(data) {
                this.saveLoggedUser(accessToken);
            }
            return data;
        });
    }

    googleLogin(accessToken) {
        return this.postRequest('GoogleLogin', { accessToken: accessToken }, false).then(data => {
            if(data) {
                this.saveLoggedUser(accessToken);
            }
            return data;
        });
    }

    saveLoggedUser(user) {
        localStorage.setItem(LOGGED_USER, user);
    }

    getLoggedUser() {
        return localStorage.getItem(LOGGED_USER);
    }
}