import Vue from "vue";
import Router from "vue-router";
import { Routes } from "@/scripts/models/enums/routes.enum.js";

Vue.use(Router);

const route = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Boleto
    {
      path: "/boleto",
      name: "boleto",

      component: () => import("./views/web/campaign/boleto.vue"),
    },

    // Termo de Anuencia (via link por e-mail)
    {
      path: "/termo-anuencia",
      name: "termo-anuencia",

      component: () => import("./views/web/campaign/boleto.vue"),
    },

    // Pagseguro
    {
      path: "/pagseguro",
      name: "pagseguro",

      component: () => import("./components/PagSeguroCheckout.vue"),
    },

    // Pagseguro
    {
      path: "/termo",
      name: "termo",

      component: () => import("./components/ConsentTermByUrl.vue"),
    },
    {
      path: "/dbf",
      name: "dbf",

      component: () => import("./views/web/council/DBF.vue"),
    },

    // Usuários
    {
      path: "/usuario",
      name: Routes.app.User,
      props: true,

      component: () => import("./views/app/user/User.vue"),
    },

    // App Campanha
    {
      path: "/app/campanha",
      component: () => import("./views/app/campaign/CampaignMain.vue"),
      children: [
        {
          path: "/",
          name: Routes.app.CampaignList,
          component: () => import("./views/app/campaign/CampaignList.vue"),
        },
        {
          path: "edicao",
          name: Routes.app.CampaignEdit,
          component: () => import("./views/app/campaign/CampaignEdit.vue"),
        },
        {
          path: "faq",
          name: Routes.app.CampaignFAQ,
          component: () => import("./views/app/campaign/CampaignFaq.vue"),
        },
      ],
    },
    {
      path: "/app/campanha/lp",

      component: () => import("./views/app/campaign/CampaignLP.vue"),
      children: [
        {
          path: "/",
          name: Routes.app.CampaignLandingPageEdit,
          component: () =>
            import("./views/app/campaign/CampaignLandingPage.vue"),
        },
      ],
    },
    {
      path: "/",

      component: () => import("./views/app/campaign/CampaignLP.vue"),
      children: [
        {
          path: "/",
          name: Routes.app.CampaignLandingPage,
          component: () =>
            import("./views/app/campaign/CampaignLandingPage.vue"),
        },
        {
          path: "/contato",
          name: "campaign-landing-page",
          component: () =>
            import("./views/app/campaign/CampaignLandingPage.vue"),
        },
        {
          path: "/politica-de-privacidade",
          name: "campaign-landing-page",
          component: () =>
            import("./views/app/campaign/CampaignLandingPage.vue"),
        },
      ],
    },

    // Dashboard
    {
      path: "/app/dashboard",

      component: () => import("./views/app/dashboard/Dashboard.vue"),
      children: [
        {
          path: "/",
          name: Routes.app.DashboardPF,
          component: () => import("./views/app/dashboard/PF.vue"),
        },
        {
          path: "campanhas",
          name: Routes.app.DashboardCompany,
          component: () => import("./views/app/dashboard/Company.vue"),
        },
        {
          path: "member-get-member",
          name: Routes.app.MemberGetMember,
          component: () =>
            import("./views/app/memberGetMember/MemberGetMember.vue"),
        },
      ],
    },

    // App Projetos
    {
      path: "/app/projeto",
      component: () => import("./views/app/project/ProjectEdit.vue"),
      children: [
        {
          path: "/",
          name: Routes.app.ProjectList,
          component: () => import("./views/app/project/ProjectList.vue"),
        },
        {
          path: "tipo",
          name: Routes.app.ProjectStep0,
          component: () => import("./views/app/project/step0.vue"),
        },
        {
          path: "passo1",
          name: Routes.app.ProjectStep1,
          component: () => import("./views/app/project/step1.vue"),
        },
        {
          path: "passo2",
          name: Routes.app.ProjectStep2,
          component: () => import("./views/app/project/step2.vue"),
        },
        {
          path: "passo3",
          name: Routes.app.ProjectStep3,
          component: () => import("./views/app/project/step3.vue"),
        },
        {
          path: "passo4",
          name: Routes.app.ProjectStep4,
          component: () => import("./views/app/project/step4.vue"),
        },
        {
          path: "frequencia-metas",
          name: Routes.app.ProjectStep50,
          component: () => import("./views/app/project/step5_0.vue"),
        },
        {
          path: "passo5",
          name: Routes.app.ProjectStep51,
          component: () => import("./views/app/project/step5.vue"),
        },
        {
          path: "galeria",
          name: Routes.app.ProjectGallery,
          component: () => import("./views/app/project/ProjectGallery.vue"),
          props: true,
        },
        {
          path: "documentos",
          name: Routes.app.ProjectDocuments,
          component: () => import("./views/app/project/Documents.vue"),
          props: true,
        },
        {
          path: "comprovacao-metas",
          name: Routes.app.ProjectAccountability,
          component: () =>
            import("./views/app/project/ProjectAccountability.vue"),
          props: true,
        },

        //remover depois
        {
          path: "empty",
          name: "empty",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/templates/empty_template.vue"),
        },
        //remover depois
        {
          path: "empty_nofooter",
          name: "empty_nofooter",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/templates/empty_template_no_footer.vue"),
        },
        //remover depois
        {
          path: "empty_nofooter_nohelp",
          name: "empty_nofooter_nohelp",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/templates/empty_template_no_footer_help.vue"),
        },
      ],
    },

    // App Voluntariado
    {
      path: "/app/volunteering",
      component: () => import("./views/app/volunteering/VolunteeringEdit.vue"),
      children: [
        {
          path: "/",
          name: Routes.app.VolunteeringList,
          component: () =>
            import("./views/app/volunteering/VolunteeringList.vue"),
        },
        {
          path: "passo1",
          name: Routes.app.VolunteeringStep1,
          component: () => import("./views/app/volunteering/step1.vue"),
        },
        {
          path: "passo2",
          name: Routes.app.VolunteeringStep2,
          component: () => import("./views/app/volunteering/step2.vue"),
        },
        {
          path: "passo3",
          name: Routes.app.VolunteeringStep3,
          component: () => import("./views/app/volunteering/step3.vue"),
        },
        {
          path: "passo5",
          name: Routes.app.VolunteeringStep5,
          component: () => import("./views/app/volunteering/step5.vue"),
        },
        {
          path: "passo6",
          name: Routes.app.VolunteeringStep6,
          component: () => import("./views/app/volunteering/step6.vue"),
        },
        {
          path: "galeria",
          name: Routes.app.VolunteeringGallery,
          component: () =>
            import("./views/app/volunteering/VolunteeringGallery.vue"),
          props: true,
        },
      ],
    },

    //Carrinho de compras
    {
      path: "/carrinho",
      component: () => import("./views/web/campaign/Carrinho.vue"),
      children: [
        {
          path: "/",
          name: Routes.web.CartLogged,
          component: () => import("./views/web/campaign/CartLogged.vue"),
        },
        {
          path: "checkout-v2",
          name: Routes.web.CheckoutV2,
          component: () => import("./views/web/checkout/Checkout.vue"),
        },
        {
          path: "order-approved",
          name: Routes.web.OrderApproved,
          component: () => import("./views/web/checkout/OrderApproved.vue"),
        },
        {
          path: "order-not-approved",
          name: Routes.web.OrderNotApproved,
          component: () => import("./views/web/checkout/OrderNotApproved.vue"),
        },
        {
          path: "checkout",
          name: Routes.web.CheckoutDefault,
          component: () => import("./views/web/campaign/CheckoutDefault.vue"),
        },
        {
          path: "gerar-boleto",
          name: Routes.web.GenerateTicket,
          component: () => import("./components/GenerateTicket.vue"),
        },
        {
          path: "refazer-doacao",
          name: Routes.web.RetryDonation,
          component: () => import("./views/web/campaign/RetryDonation.vue"),
        },
        {
          path: "obrigado",
          name: Routes.app.Congrats,
          component: () => import("./views/app/campaign/Congrats.vue"),
        },
        {
          path: "login",
          name: Routes.app.LoginByToken,
          component: () => import("./components/login/LoginByToken.vue"),
        },
      ],
    },

    //Perfil PF
    {
      path: "/perfil",
      name: Routes.web.Profile,
      component: () => import("./views/web/profile/Profile.vue"),
    },

    //Meios de pagamento
    {
      path: "/meios-pagamento",
      name: Routes.web.PaymentMethods,
      component: () => import("./views/web/profile/PaymentMethods.vue"),
    },

    //Projeto
    {
      path: "/projeto",
      component: () => import("./views/web/project/Project.vue"),
      children: [
        {
          path: "/",
          name: Routes.web.ProjectLP,
          component: () => import("./views/web/project/ProjectLP.vue"),
        },
      ],
    },

    //Prestação de Contas
    {
      path: "/prestacao-contas",

      component: () => import("./views/app/campaign/CampaignLP.vue"),
      children: [
        {
          path: "/prestacao-contas",
          name: Routes.web.ProjectAccountabilityLP,
          component: () =>
            import("./views/web/project/ProjectAccountabilityLP.vue"),
        },
      ],
    },
    //Projeto
    {
      path: "/projetos-da-campanha",
      component: () => import("./views/web/project/Project.vue"),
      children: [
        {
          path: "/",
          name: Routes.web.ProjectAll,
          component: () => import("./views/web/project/ProjectAll.vue"),
        },
      ],
    },

    // Instituição
    {
      path: "/app/instituicao",
      component: () => import("./views/app/institution/Institution.vue"),
      children: [
        {
          path: "/",
          name: Routes.app.InstitutionList,
          component: () =>
            import("./views/app/institution/InstitutionList.vue"),
        },
        {
          path: "perfil",
          name: Routes.app.InstitutionProfile,
          component: () => import("./views/app/institution/Profile.vue"),
        },
        {
          path: "sobre",
          name: Routes.app.InstitutionAbout,
          component: () => import("./views/app/institution/About.vue"),
          props: true,
        },
      ],
    },

    // Gestão
    {
      path: "/app/gestao",
      component: () => import("./views/app/management/Management.vue"),
      children: [
        {
          path: "empresa",
          name: Routes.app.ManagementCompany,
          component: () => import("./views/app/management/Company.vue"),
        },
        {
          path: "usuario",
          name: Routes.app.ManagementUser,
          component: () => import("./views/app/management/User.vue"),
        },
        {
          path: "instituicao",
          name: Routes.app.ManagementInstitution,
          component: () => import("./views/app/management/Institution.vue"),
        },
        {
          path: "conselho",
          name: Routes.app.ManagementCouncil,
          component: () => import("./views/app/management/Council.vue"),
        },
        {
          path: "boleto",
          name: Routes.app.ManagementBankSlip,
          component: () => import("./views/app/management/BankSlip.vue"),
        },
        {
          path: "meta",
          name: Routes.app.ManagementGoal,
          component: () => import("./views/app/management/Goal.vue"),
        },
        {
          path: "config",
          component: () => import("./views/app/management/Management.vue"),
          children: [
            {
              path: "taxas",
              name: Routes.app.ManagementTax,
              component: () => import("./views/app/management/config/Tax.vue"),
            },
            {
              path: "convenios",
              name: Routes.app.ManagementTicket,
              component: () =>
                import("./views/app/management/config/Ticket.vue"),
            },
          ],
        },
        {
          path: "assinatura",
          name: Routes.app.ManagementSignature,
          component: () => import("./views/app/management/Signature.vue"),
          props: true,
        },
        {
          path: "assinatura-manual",
          name: Routes.app.ManagementManualSignature,
          component: () => import("./views/app/management/ManualSignature.vue"),
          props: true,
        },
      ],
    },

    // Empresa
    {
      path: "/app/empresa",
      component: () => import("./views/app/company/Company.vue"),
      children: [
        {
          path: "perfil",
          name: Routes.app.CompanyProfile,
          component: () => import("./views/app/company/Profile.vue"),
        },
      ],
    },

    // Conselho
    {
      path: "/app/conselho",
      component: () => import("./views/app/council/Council.vue"),
      children: [
        {
          path: "perfil",
          name: Routes.app.CouncilProfile,
          component: () => import("./views/app/council/Profile.vue"),
        },
      ],
    },

    // Fluxo de recursos
    {
      path: "/app/rh",
      component: () => import("./views/app/resourceFlow/ResourceFlow.vue"),
      children: [
        {
          path: "conciliacao",
          name: Routes.app.HrConciliation,
          component: () =>
            import("./views/app/resourceFlow/hr_conciliation.vue"),
        },
        {
          path: "fluxo-recursos",
          name: Routes.app.ResourceFlowList,
          component: () =>
            import("./views/app/resourceFlow/conciliationBase.vue"),
        },
        {
          path: "conciliation-company",
          name: Routes.app.ConciliationCompany,
          component: () =>
            import("./views/app/resourceFlow/conciliationCompany.vue"),
        },
        {
          path: "status-zero",
          name: Routes.app.StatusZero,
          component: () => import("./views/app/resourceFlow/statusZero.vue"),
        },
        {
          path: "transfer-to-auc",
          name: Routes.app.TransferToAUC,
          component: () => import("./views/app/resourceFlow/transferToAUC.vue"),
        },
        {
          path: "concilied-by-company",
          name: Routes.app.ConciliedByCompany,
          component: () =>
            import("./views/app/resourceFlow/conciliedByCompany.vue"),
        },
        {
          path: "on-conciliation-auc",
          name: Routes.app.OnConciliationAUC,
          component: () =>
            import("./views/app/resourceFlow/onConciliationAUC.vue"),
        },
        {
          path: "concilied-auc",
          name: Routes.app.ConciliedAUC,
          component: () => import("./views/app/resourceFlow/conciliedAUC.vue"),
        },
        {
          path: "pendent-accept-to-institution",
          name: Routes.app.PendentAcceptToInstitution,
          component: () =>
            import("./views/app/resourceFlow/pendentAcceptToInstitution.vue"),
        },
        {
          path: "pendent-transfer-to-institution",
          name: Routes.app.PendentTransferToInstitution,
          component: () =>
            import("./views/app/resourceFlow/pendentTransferToInstitution.vue"),
        },
        {
          path: "on-conciliation-institution",
          name: Routes.app.OnConciliationToInstitution,
          component: () =>
            import("./views/app/resourceFlow/onConciliationToInstitution.vue"),
        },
        {
          path: "concilied",
          name: Routes.app.Concilied,
          component: () => import("./views/app/resourceFlow/concilied.vue"),
        },
        {
          path: "declined",
          name: Routes.app.Declined,
          component: () => import("./views/app/resourceFlow/declined.vue"),
        },
      ],
    },

    // Login
    {
      path: "/login",
      name: Routes.web.Login,
      props: true,

      component: () => import("./views/web/login/Login.vue"),
    },
    {
      path: "/google",
      name: Routes.web.LoginGoogle,

      component: () => import("./views/web/login/GoogleAuth.vue"),
    },
    {
      path: "/facebook",
      name: Routes.web.LoginFacebook,

      component: () => import("./views/web/login/FacebookAuth.vue"),
    },
    {
      path: "/esqueci-senha",
      name: Routes.web.LoginForgotPassword,
      component: () => import("./views/web/login/ForgotPassword.vue"),
    },
    {
      path: "/registro-senha",
      name: Routes.web.LoginPasswordRegistration,
      props: true,
      component: () => import("./views/web/login/PasswordRegistration.vue"),
    },
    {
      path: "/verificacao-conta",
      name: Routes.web.LoginVerifyAccount,

      component: () => import("./views/web/login/VerifyAccount.vue"),
    },
    {
      path: "/verificacao-email",
      name: Routes.web.LoginVerifyEmail,

      component: () => import("./views/web/login/VerifyEmail.vue"),
    },
    {
      path: "/redefinicao-senha",
      name: Routes.web.LoginVerifyPassword,

      component: () => import("./views/web/login/VerifyPassword.vue"),
    },
    {
      path: "*",
      name: Routes.NotFound,
      component: () => import("./views/web/NotFound.vue"),
    },
    {
      path: "/recibodoacao",
      name: Routes.web.Receipt,

      component: () => import("./views/web/receipt/ReceiptList.vue"),
    },
    {
      path: "/informe",
      name: Routes.web.Income,

      component: () => import("./views/web/receipt/IncomeReport.vue"),
    },
    {
      path: "/doacao-simplificada",
      name: "doacao-simplificada",

      component: () => import("../doacao-simplificada/src/main.js"),
    },
    {
      path: "/voluntariado",
      name: Routes.app.VolunteeringLP,

      component: () =>
        import("./views/app/volunteering/VolunteeringLandingPage.vue"),
    },
    {
      path: "/app/doar-pix",
      name: Routes.web.Income,

      component: () => import("./components/RedoPix.vue"),
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0,0);
    return { top: 0 };
  },
});

export default route;
